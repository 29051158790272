import {
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
  IonList,
  IonListHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonIcon,
  IonBadge,
  IonModal,
  IonCardContent,
  IonText,
} from "@ionic/react";
import { cartOutline, information, informationCircle, mail, mailOpen } from "ionicons/icons";
import React, { useState } from "react";
import marcellas from "../../../assets/Images/marcellas.jpg";
import Cart from "../../Cart";

const StartPreorderStep = () => {
  return (
    <IonRow className="h-full w-full">
      <IonCol size="12" className="h-full flex items-center justify-center overflow-auto">
        <IonCard>
          <img className="object-cover h-40 w-full" src={marcellas} />
          <IonCardHeader>
            <IonCardSubtitle>Catering/Pickup</IonCardSubtitle>
            <IonCardTitle>Preorder</IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="pb-4">
            <IonRow>
              <IonCol>
                <IonText>
                Our preorder system requires orders to be placed at least 48
                hours in advance. Orders requested sooner will be fulfilled
                in store based on availability. Once you place a preorder, our
                staff will be notified, and we will follow up with a
                confirmation email.
                </IonText>
              </IonCol>
            </IonRow>
          </IonCardContent>
          <IonRow className="p-2 w-full">
              <IonCol size="12" className="ion-align-self-end py-0 p-2">
                <div className="bg-red-500 rounded-lg flex items-center justify-center ion-no-margin ion-no-padding">
                <IonIcon className="p-2" color="light" size="large" icon={informationCircle}></IonIcon>
                <IonText color="light" className="text-xs pl-1">
                  Updates are still being made to improve this ordering system.
                </IonText>
                </div>
              </IonCol>
              
              {/* <IonCol size="2" className="flex items-center justify-center">
                <IonButton><IonIcon slot="icon-only" icon={mail}></IonIcon></IonButton>
              </IonCol> */}
          </IonRow>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default StartPreorderStep;