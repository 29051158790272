import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonText,
  IonBadge,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonLabel
} from "@ionic/react";
import { addCircle, removeCircle, cartOutline } from "ionicons/icons";
import { v4 as uuidv4 } from "uuid"; // Unique keys for cart items
import { toast } from "react-toastify";
import Cart from "../../Cart";

const MenuSelectionStep = ({
  cateringMenu,
  loading,
  error,
  setFormData,
  selectedItems,
  setSelectedItems,
}) => {
  const [showCartModal, setShowCartModal] = useState(false); // Control cart modal visibility
  const [selectedCategory, setSelectedCategory] = useState("all");

  const allItems = Object.values(cateringMenu).flat(); // Flatten all items for "all" category

  const handleSegmentChange = (event) => {
    setSelectedCategory(event.detail.value);
  };

  const decreaseItemQuantity = (itemName) => {
    setSelectedItems((prevItems) =>
      prevItems.reduce((acc, item) => {
        if (item.name === itemName) {
          if (item.quantity > 1) {
            // Decrease quantity by 1 if more than 1
            acc.push({ ...item, quantity: item.quantity - 1 });
          } else {
            // Remove item if quantity is 1
            toast.info(`${item.name} removed from the cart!`);
          }
        } else {
          acc.push(item); // Keep other items unchanged
        }
        return acc;
      }, [])
    );

    // Update formData as well
    setFormData((prevData) => ({
      ...prevData,
      selectedItems: prevData.selectedItems.reduce((acc, item) => {
        if (item.name === itemName) {
          if (item.quantity > 1) {
            acc.push({ ...item, quantity: item.quantity - 1 });
          }
        } else {
          acc.push(item);
        }
        return acc;
      }, []),
    }));
  };

  const addSelectedItem = (item, category) => {
    if (!item.name) {
      toast.error("Item name is required to add to cart!");
      return;
    }
  
    setSelectedItems((prevItems) => {
      const existingItem = prevItems.find(
        (cartItem) => cartItem.name === item.name
      );
  
      if (existingItem) {
        return prevItems.map((cartItem) =>
          cartItem.name === item.name
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        const newItem = {
          name: item.name,
          category,
          quantity: 1,
          price: item.price || 0,
        };
        return [...prevItems, newItem];
      }
    });
  
    setFormData((prevData) => {
      const selectedItems = prevData.selectedItems || []; // Initialize to an empty array if undefined
  
      const existingItem = selectedItems.find(
        (cartItem) => cartItem.name === item.name
      );
  
      if (existingItem) {
        return {
          ...prevData,
          selectedItems: selectedItems.map((cartItem) =>
            cartItem.name === item.name
              ? { ...cartItem, quantity: cartItem.quantity + 1 }
              : cartItem
          ),
        };
      } else {
        const newItem = {
          name: item.name,
          category,
          quantity: 1,
          price: item.price || 0,
        };
        return {
          ...prevData,
          selectedItems: [...selectedItems, newItem],
        };
      }
    });
  
    toast.success(`${item.name} added to the cart!`);
  };
  

  const removeSelectedItem = (itemName) => {
    // Filter out the item to be removed
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item.name !== itemName)
    );

    // Update formData as well
    setFormData((prevData) => ({
      ...prevData,
      selectedItems: prevData.selectedItems.filter(
        (item) => item.name !== itemName
      ),
    }));

    // Show toast notification for removal
    toast.info("Item removed from the cart!");
  };

  const isItemInCart = (itemName) => {
    return selectedItems.some((cartItem) => cartItem.name === itemName);
  };

  // const getItemInCart = (itemName) => {
  //   return selectedItems.find((cartItem) => cartItem.name === itemName);
  // };

  const updateItemQuantity = (itemName, newQuantity) => {
    setSelectedItems((prevItems) =>
      prevItems.map((item) =>
        item.name === itemName ? { ...item, quantity: newQuantity } : item
      )
    );

    setFormData((prevData) => ({
      ...prevData,
      selectedItems: prevData.selectedItems.map((item) =>
        item.name === itemName ? { ...item, quantity: newQuantity } : item
      ),
    }));
  };

  return (
    <IonRow className="h-full w-full overflow-auto">
      <IonModal
        isOpen={showCartModal}
        onDidDismiss={() => setShowCartModal(false)}
      >
        <Cart
          selectedItems={selectedItems}
          setShowCartModal={setShowCartModal}
          updateItemQuantity={updateItemQuantity}
          removeSelectedItem={removeSelectedItem}
        />
      </IonModal>

      {loading && <IonCol>Loading menu...</IonCol>}
      {error && <IonCol>Error loading menu: {error}</IonCol>}

      <IonCol>
        <IonCard className="shadow-none pb-0 mb-0 relative">
          <IonCardHeader>
            <IonCardSubtitle>Catering Menu</IonCardSubtitle>
            <IonCardTitle>Select Item</IonCardTitle>
          </IonCardHeader>
          <div className="absolute right-0 top-0">
            <IonButton
              size="large"
              shape="round"
              color="tertiary"
              onClick={() => setShowCartModal(true)}
            >
              <IonIcon
                slot="icon-only"
                color="dark"
                icon={cartOutline}
              ></IonIcon>
            </IonButton>
            <IonBadge
              shape="rounded"
              className="absolute right-0 top-0 rounded-full flex items-center justify-center aspect-square w-5"
            >
              {selectedItems.length}
            </IonBadge>
          </div>
        </IonCard>
      </IonCol>

      {/* Scrollable Items Section */}
      <IonCol
        size="12"
        className="hide-scroll ion-no-margin ion-no-padding gap-2 bg-none flex overflow-x-auto snap-x snap-mandatory scroll-smooth no-scrollbar overscroll-none no-scroll rounded-none"
      >
        {(selectedCategory === "all"
          ? allItems
          : cateringMenu[selectedCategory]
        ).map((item) => {
          const itemInCart = isItemInCart(item.name);
          
          return (
            <div
              key={item.id || uuidv4()} // Ensure this key is unique
              className="flex-none w-[300px] min-w-[300px] flex justify-center items-center snap-center rounded-md"
            >
              <IonCard className="h-48 w-full">
                {/* <img
                  className="object-cover w-[300px] h-[175px]"
                  src="https://placehold.co/300x175"
                  alt={item.name}
                /> */}

                <IonCardHeader className="relative">
                  <IonCardSubtitle>
                    {item.category}
                  </IonCardSubtitle>
                  <IonCardTitle className="text-lg">
                    {item.name}
                  </IonCardTitle>
                  <span className="absolute top-2 right-2 text-xl font-bold">
                    ${item.price}.00
                  </span>
                </IonCardHeader>

                <IonCardContent className="text-xs">
                  <IonText className="line-clamp-3 min-h-10">{item.description}</IonText>
                </IonCardContent>

                <IonRow className="ion-justify-content-between ion-align-items-center">
                  <IonCol size="auto">
                    {itemInCart && (
                      <IonButton
                        size="small"
                        color="danger"
                        onClick={() => decreaseItemQuantity(item.name)}
                      >
                        <IonIcon icon={removeCircle} />
                      </IonButton>
                    )}
                    <IonButton
                      size="small"
                      onClick={() => addSelectedItem(item, selectedCategory)}
                    >
                      <IonIcon icon={addCircle} />
                    </IonButton>
                  </IonCol>
                  <IonCol size="auto">
                    {itemInCart && (
                      <IonText color="dark">
                        Quantity:{" "}
                        {selectedItems.find(
                          (cartItem) => cartItem.name === item.name
                        )?.quantity || 0}
                      </IonText>
                    )}
                  </IonCol>
                </IonRow>
              </IonCard>
            </div>
          );
        })}
      </IonCol>

      {/* Category Selector */}
      <IonCol className="px-2">
        <IonSegment
          className="w-full p-2 hide-scroll"
          scrollable={true}
          value={selectedCategory}
          onIonChange={handleSegmentChange}
        >
          <IonSegmentButton value="all">
            <IonLabel>All</IonLabel>
          </IonSegmentButton>
          {Object.keys(cateringMenu).map((category) => (
            <IonSegmentButton key={category} value={category}>
              <IonLabel>
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </IonLabel>
            </IonSegmentButton>
          ))}
        </IonSegment>
      </IonCol>
    </IonRow>
  );
};

export default MenuSelectionStep;
