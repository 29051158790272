import React, { useEffect, useState } from "react";
import {
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  IonText,
  IonInput,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
} from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

const DeliveryInfoStep = ({
  formData,
  setFormData,
  date,       // Receive `date` as a prop
  setDate,    // Receive `setDate` as a prop
}) => {
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null); // Add maxDate state
  const [showCalendar, setShowCalendar] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
    const calculateCutoffDates = () => {
      const today = new Date();
      let cutoffDate = new Date(today);
      let day = cutoffDate.getDay();

      // Set minimum date considering weekends (Sundays and Mondays) are blocked
      if (day === 0) {
        cutoffDate.setDate(cutoffDate.getDate() + 4); // Move to Thursday
      } else if (day === 6) {
        cutoffDate.setDate(cutoffDate.getDate() + 5); // Move to Thursday
      } else {
        cutoffDate.setDate(cutoffDate.getDate() + 2);
        let newDay = cutoffDate.getDay();
        if (newDay === 0) {
          cutoffDate.setDate(cutoffDate.getDate() + 3); // Skip Sunday, Monday to Thursday
        } else if (newDay === 1) {
          cutoffDate.setDate(cutoffDate.getDate() + 2); // Skip Monday to Thursday
        }
      }

      // Calculate maxDate as 3 months from today
      const maxLimitDate = moment(today).add(3, "months").toDate();

      setMinDate(cutoffDate);
      setMaxDate(maxLimitDate); // Set maxDate
    };

    calculateCutoffDates();
  }, []);

  useEffect(() => {
    const getNext7Days = () => {
      const days = [];
      for (let i = 0; i < 7; i++) {
        const day = moment(minDate).add(i, "days");
        if (day.day() !== 0 && day.day() !== 1) {
          const formattedDate = day.format("MMMM D, YYYY");

          // Exclude blocked dates
          if (
            formattedDate !== "November 28, 2024" &&
            formattedDate !== "November 29, 2024" &&
            formattedDate !== "November 30, 2024" &&
            formattedDate !== "December 1, 2024" &&
            formattedDate !== "December 2, 2024" &&
            formattedDate !== "December 24, 2024"
          ) {
            days.push(formattedDate);
          }
        }
      }
      setAvailableDates(days);
    };

    if (minDate) {
      getNext7Days();
    }
  }, [minDate]);

  // Updated handleDateChange
  const handleDateChange = (selectedDate) => {
    const formattedDate = moment(selectedDate).format("MMMM D, YYYY");

    // Check if the selected date from the calendar is in the available dates
    if (!availableDates.includes(formattedDate)) {
      setAvailableDates((prevDates) => [...prevDates, formattedDate]); // Add it to available dates if not present
    }

    setDate(selectedDate); // Centralized date update

    // Update formData with the formatted date
    setFormData((prevData) => ({
      ...prevData,
      date: formattedDate, // Ensure consistency with `date` as the key in formData
    }));

    setShowCalendar(false);
  };

  // Updated handleDropdownChange
  const handleDropdownChange = (selectedDateString) => {
    const dateObj = moment(selectedDateString, "MMMM D, YYYY").toDate(); // Convert string to date object
    setDate(dateObj);

    // Update formData with the selected date
    setFormData((prevData) => ({
      ...prevData,
      date: selectedDateString, // Store formatted string directly in formData
    }));
  };

  return (
    <IonRow className="overflow-auto w-full h-full">
      <IonCol size="12">
        <IonCard className="shadow-none">
          <IonCardHeader>
            <IonCardSubtitle>Local Gems</IonCardSubtitle>
            <IonCardTitle>Delivery/Catering</IonCardTitle>
          </IonCardHeader>

          <IonList className="w-full">
            <IonRow>
              <IonCol size="12" className="text-left text-gray-600 px-4">
                <IonText>
                  Catering is done through a 3rd Party small business, please
                  contact them with any delivery requests. Local Gems at
                  (832) 693-9729, or email them at contact@shoplocalgems.com
                </IonText>
              </IonCol>
            </IonRow>

            {showCalendar && (
              <IonRow>
                <IonCol size="12" sizeMd="8" className="mx-auto">
                  <Calendar
                    onChange={handleDateChange}
                    value={date}
                    minDate={minDate}
                    maxDate={maxDate} // Set maxDate to limit selection to 3 months from today
                    className="text-mGreen mx-auto border-4 drop-shadow-md border-mGreen p-2"
                    tileDisabled={({ date }) => {
                      const day = date.getDay();
                      const formattedDate = moment(date).format("MMMM D, YYYY");
                      return (
                        day === 0 || 
                        day === 1 ||
                        formattedDate === "November 28, 2024" ||
                        formattedDate === "November 29, 2024" ||
                        formattedDate === "November 30, 2024" ||
                        formattedDate === "December 1, 2024" ||
                        formattedDate === "December 2, 2024" ||
                        formattedDate === "December 24, 2024" || 
                        formattedDate === "December 25, 2024" || 
                        formattedDate === "December 26, 2024" || 
                        formattedDate === "December 27, 2024" || 
                        formattedDate === "December 28, 2024" || 
                        formattedDate === "December 29, 2024" || 
                        formattedDate === "December 30, 2024" || 
                        formattedDate === "December 31, 2024" || 
                        formattedDate === "January 1, 2024"
                      );
                    }}
                  />
                </IonCol>
              </IonRow>
            )}
            <IonRow className="mx-auto flex items-end justify-center px-2">
              <span className="text-mRed">*Use the calendar to select future dates not displayed.</span>
              <IonCol size="9" sizeMd="10">
                <IonSelect
                  label="Event Date"
                  labelPlacement="floating"
                  className="rounded-md bg-slate-100 text-slate-800 px-2 w-full"
                  onIonChange={(e) => handleDropdownChange(e.detail.value)}
                  value={
                    date
                      ? moment(date).format("MMMM D, YYYY")
                      : undefined
                  }
                >
                  {availableDates.map((day, index) => (
                    <IonSelectOption key={index} value={day}>
                      {day}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>

              <IonCol size="3" sizeMd="2" className="text-center">
                <span className="text-sm">Calendar</span>
                <IonButton
                  className="w-full"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <IonIcon icon={calendarOutline}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12">
                <IonItem lines="none">
                  <IonSelect
                    value={formData.tempType}
                    labelPlacement="floating"
                    label="Food Temp"
                    className="bg-slate-100 text-slate-800 px-2 rounded-md"
                    onIonChange={(e) =>
                      setFormData({ ...formData, tempType: e.detail.value })
                    }
                  >
                    <IonSelectOption value="Cold">Cold</IonSelectOption>
                    <IonSelectOption value="Hot">Hot</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>

            {/* Other form fields */}
            <IonRow>
              <IonCol size="6">
                <IonItem>
                  <IonInput
                    placeholder="Street"
                    label="Street"
                    value={formData.street}
                    onIonChange={(e) =>
                      setFormData({ ...formData, street: e.detail.value })
                    }
                  />
                </IonItem>
              </IonCol>

              <IonCol size="6">
                <IonItem>
                  <IonInput
                    placeholder="San Francisco"
                    label="City"
                    value={formData.city}
                    onIonChange={(e) =>
                      setFormData({ ...formData, city: e.detail.value })
                    }
                  />
                </IonItem>
              </IonCol>

              <IonCol size="6">
                <IonItem>
                  <IonSelect
                    label="State"
                    labelPlacement="floating"
                    className="rounded-md text-slate-800"
                    onIonChange={(e) =>
                      setFormData({ ...formData, state: e.detail.value })
                    }
                    value={formData.state || "California"}
                  >
                    <IonSelectOption value="California">California</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonList>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default DeliveryInfoStep;
