import React, { useEffect, useState } from "react";
import {
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  IonText,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
} from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

const PickupInfoStep = ({
  formData,
  setFormData,
  date,          // Correctly receive date as a prop
  setDate,       // Correctly receive setDate as a prop
}) => {
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null); // Add maxDate state
  const [showCalendar, setShowCalendar] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
    const calculateCutoffDates = () => {
      const today = new Date();
      let cutoffDate = new Date(today);
      let day = cutoffDate.getDay();

      if (day === 0) {
        cutoffDate.setDate(cutoffDate.getDate() + 4); // Move to Thursday
      } else if (day === 6) {
        cutoffDate.setDate(cutoffDate.getDate() + 5); // Move to Thursday
      } else {
        cutoffDate.setDate(cutoffDate.getDate() + 2);
        let newDay = cutoffDate.getDay();
        if (newDay === 0) {
          cutoffDate.setDate(cutoffDate.getDate() + 3); // Skip Sunday, Monday to Thursday
        } else if (newDay === 1) {
          cutoffDate.setDate(cutoffDate.getDate() + 2); // Skip Monday to Thursday
        }
      }

      // Set maxDate to 3 months from today
      const maxLimitDate = moment(today).add(3, "months").toDate();

      setMinDate(cutoffDate);
      setMaxDate(maxLimitDate); // Set maxDate
    };

    calculateCutoffDates();
  }, []);

  useEffect(() => {
    const getNext7Days = () => {
      const days = [];
      for (let i = 0; i < 7; i++) {
        const day = moment(minDate).add(i, "days");
        if (day.day() !== 0 && day.day() !== 1) {
          const formattedDate = day.format("MMMM D, YYYY");

          // Exclude blocked dates
          if (
            !["November 28, 2024", "November 29, 2024", "November 30, 2024", 
              "December 1, 2024", "December 2, 2024", "December 24, 2024"].includes(formattedDate)
          ) {
            days.push(formattedDate);
          }
        }
      }
      setAvailableDates(days);
    };

    if (minDate) {
      getNext7Days();
    }
  }, [minDate]);

  // Updated handleDateChange
  const handleDateChange = (selectedDate) => {
    const formattedDate = moment(selectedDate).format("MMMM D, YYYY");

    // Check if the selected date from the calendar is in the available dates
    if (!availableDates.includes(formattedDate)) {
      setAvailableDates((prevDates) => [...prevDates, formattedDate]); // Add it to available dates if not present
    }

    setDate(selectedDate); // Centralized date update

    // Update formData with the formatted date
    setFormData((prevData) => ({
      ...prevData,
      date: formattedDate, // Ensure consistency with `date` as the key in formData
    }));

    setShowCalendar(false);
  };

  // Updated handleDropdownChange
  const handleDropdownChange = (selectedDateString) => {
    const dateObj = moment(selectedDateString, "MMMM D, YYYY").toDate(); // Convert string to date object
    setDate(dateObj);

    // Update formData with the selected date
    setFormData((prevData) => ({
      ...prevData,
      date: selectedDateString, // Store formatted string directly in formData
    }));
  };

  return (
    <IonRow className="h-full w-full overflow-auto">
      <IonCol size="12">
        <IonCard className="shadow-none">
          <IonCardHeader>
            <IonCardSubtitle>In store</IonCardSubtitle>
            <IonCardTitle>Pickup</IonCardTitle>
          </IonCardHeader>

          <IonList className="w-full">
            <IonRow>
              <IonCol size="12" className="text-left text-gray-600 px-4">
                <IonText>
                  Pickups are in store. Please complete the pickup details for
                  your order. Our pickups require at least 48 hours in advance.
                </IonText>
              </IonCol>
            </IonRow>

            {showCalendar && (
              <IonCol size="12" sizeMd="6">
                <Calendar
                  onChange={handleDateChange} // Call handleDateChange when selecting a date
                  value={date} // Use `date` directly here
                  minDate={minDate}
                  maxDate={maxDate} // Set maxDate to limit selection to 3 months from today
                  className="text-mGreen mx-auto border-4 drop-shadow-md border-mGreen p-2"
                  tileDisabled={({ date }) => {
                    const day = date.getDay();
                    const formattedDate = moment(date).format("MMMM D, YYYY");

                    // Disable Sundays, Mondays, and the blocked dates
                    return (
                      day === 0 ||
                      day === 1 ||
                      ["November 28, 2024", "November 29, 2024", "November 30, 2024",
                        "December 1, 2024", "December 2, 2024", "December 24, 2024",
                        "December 25, 2024", "December 26, 2024", "December 27, 2024", 
                        "December 28, 2024", "December 29, 2024", "December 30, 2024", 
                        "December 31, 2024", "January 1, 2024"
                      ].includes(formattedDate)
                    );
                  }}
                />
              </IonCol>
            )}
            <IonRow className="mx-auto flex items-end justify-center px-2">
              <span className="text-mRed">*Use the calendar to select future dates not displayed.</span>
              <IonCol size="9" sizeMd="10">
                <IonSelect
                  label="Pickup Date"
                  labelPlacement="floating"
                  className="rounded-md bg-slate-100 text-slate-800 px-2 w-full"
                  onIonChange={(e) => handleDropdownChange(e.detail.value)}
                  value={date ? moment(date).format("MMMM D, YYYY") : undefined} // Use `date` directly
                >
                  {availableDates.map((day, index) => (
                    <IonSelectOption key={index} value={day}>
                      {day}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>

              <IonCol size="3" sizeMd="2" className="text-center">
                <span className="text-sm">Calendar</span>
                <IonButton
                  className="w-full"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <IonIcon icon={calendarOutline}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonItem lines="none">
                  <IonSelect
                    labelPlacement="floating"
                    value={formData.pickupTime}
                    label="Pickup Time"
                    className="bg-slate-100 text-slate-800 px-2 rounded-md"
                    onIonChange={(e) =>
                      setFormData({ ...formData, pickupTime: e.detail.value })
                    }
                  >
                    <IonSelectOption value="10am">10am</IonSelectOption>
                    <IonSelectOption value="11am">11am</IonSelectOption>
                    <IonSelectOption value="2pm">2pm</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <IonItem lines="none">
                  <IonSelect
                    value={formData.tempType}
                    labelPlacement="floating"
                    label="Food Temp"
                    className="bg-slate-100 text-slate-800 px-2 rounded-md"
                    onIonChange={(e) =>
                      setFormData({ ...formData, tempType: e.detail.value })
                    }
                  >
                    <IonSelectOption value="Cold">Cold</IonSelectOption>
                    <IonSelectOption value="Hot">Hot</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonList>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default PickupInfoStep;
