import React, { useEffect, useState } from "react";
import {
  IonApp,
  IonPage,
  IonToolbar,
  IonContent,
  IonIcon,
  IonLabel,
  IonCol,
  IonRow,
  IonSegmentButton,
  IonFooter,
  IonSegment,
  IonSpinner,
} from "@ionic/react";
import { home, cart, newspaperOutline } from "ionicons/icons";

import Preorder from "./components/Layout/Tabs/Preorder";
import Home from "./components/Layout/Tabs/Home";
import Menu from "./components/Layout/Tabs/Menu";
import Navbar from "./components/Layout/Navbar";
// import SideMenu from "./components/SideMenu";

function App() {
  const [activeTab, setActiveTab] = useState("home");
  const [loading, setLoading] = useState(true);
  const [isPreorderEnabled] = useState(true); // Disable the Preorder tab

  const renderActiveTab = () => {
    switch (activeTab) {
      case "preorder":
        return <Preorder />;
      case "landing":
        return <Home setActiveTab={setActiveTab} />;
      case "menu":
        return <Menu />;
      default:
        return <Home setActiveTab={setActiveTab} />;
    }
  };

  useEffect(() => {
    setLoading(true);
    const loadTab = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };
    loadTab();
  }, [activeTab]);


  return (
    <IonApp>
      {/* <SideMenu/> */}
      <IonPage id="main-content">
        <Navbar />
        {loading ? (
          <IonContent className="h-full m-0 p-0">
            <IonRow className="h-full">
              <IonCol size="12" className="flex items-center justify-center">
                <IonSpinner name="crescent" />
              </IonCol>
            </IonRow>
          </IonContent>
        ) : (
          renderActiveTab()
        )}

        <IonFooter>
          <IonToolbar color="danger" className="flex items-center justify-center">
            <IonSegment className="max-w-2xl mx-auto" value={activeTab} onIonChange={(e) => setActiveTab(e.detail.value)}>              {isPreorderEnabled && ( // Conditionally render the Preorder tab
                <IonSegmentButton value="preorder">
                  <IonLabel>Preorder</IonLabel>
                  <IonIcon icon={cart}></IonIcon>
                </IonSegmentButton>
              )}
              <IonSegmentButton value="home">
                <IonLabel>Home</IonLabel>
                <IonIcon icon={home}></IonIcon>
              </IonSegmentButton>
              <IonSegmentButton value="menu">
                <IonLabel>Menu</IonLabel>
                <IonIcon icon={newspaperOutline}></IonIcon>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </IonApp>
  );
}

export default App;
