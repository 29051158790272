import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

function Navbar() {
  return (
    <IonHeader>
      <IonToolbar color="primary">
            <IonButtons slot="end">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
        <IonTitle className="text-white text-center text-2xl">Marcellas Lasagneria</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}

export default Navbar;