import React from 'react';
import { IonSpinner, IonText, IonRow, IonCol } from '@ionic/react';

function ConfirmationStep({ emailLoading, submitted }) {
  return (
    <IonRow className="h-full w-full flex items-center justify-center">
      {emailLoading && (
        <IonCol className="ion-text-center h-full">
          <IonSpinner name="crescent" />
          <IonText>Sending your order...</IonText>
        </IonCol>
      )}

      {!emailLoading && submitted && (
        <IonCol className="ion-text-center">
          <IonText color="success">
            <h2>Thank you for your order!</h2>
          </IonText>
          <IonText>
            <p>Your order has been successfully placed we'll get to you with a confirmation on your order</p>
          </IonText>
        </IonCol>
      )}

      {!emailLoading && !submitted && (
        <IonCol className="ion-text-center">
          <IonText color="danger">
            <h2>Something went wrong!</h2>
          </IonText>
          <IonText>
            <p>We couldn't process your order. Please try again.</p>
          </IonText>
        </IonCol>
      )}
    </IonRow>
  );
}

export default ConfirmationStep;
